import React, { Fragment } from 'react';
import Component from '@reactions/component';
import { Router, navigate, Link } from '@reach/router';
import axios from 'axios';

const UrlForm = ({ onSubmit }) => (
  <Component initialState={{ url: '' }}>
    {({ state, setState }) => (
      <Fragment>
        <h1>API URL</h1>
        <form
          onSubmit={e => {
            e.preventDefault();
            onSubmit(state.url);
          }}
        >
          <input
            type="url"
            value={state.url}
            onChange={e => setState({ url: e.target.value })}
            required
          />
          <br />
          <button type="submit" disabled={!state.url}>
            Set URL
          </button>
        </form>
      </Fragment>
    )}
  </Component>
);

const SignupForm = ({ onSubmit }) => (
  <Component initialState={{ email: '', username: '', password: '' }}>
    {({ state, setState }) => (
      <Fragment>
        <h1>Signup</h1>
        <form
          onSubmit={e => {
            e.preventDefault();
            onSubmit(state);
          }}
        >
          <input
            type="email"
            placeholder="Enter email"
            value={state.email}
            onChange={e => setState({ email: e.target.value })}
          />
          <br />
          <input
            type="text"
            placeholder="Enter username"
            value={state.username}
            onChange={e => setState({ username: e.target.value })}
          />
          <br />
          <input
            type="password"
            placeholder="Enter password"
            value={state.password}
            onChange={e => setState({ password: e.target.value })}
          />
          <br />
          <button type="submit" disabled={!state.username || !state.email || !state.password}>
            Submit
          </button>
        </form>
      </Fragment>
    )}
  </Component>
);

const LoginForm = ({ onSubmit }) => (
  <Component initialState={{ login: '', password: '' }}>
    {({ state, setState }) => (
      <Fragment>
        <h1>Login</h1>
        <form
          onSubmit={e => {
            e.preventDefault();
            onSubmit(state);
          }}
        >
          <input
            type="text"
            placeholder="Enter email or username"
            value={state.login}
            onChange={e => setState({ login: e.target.value })}
          />
          <br />
          <input
            type="password"
            placeholder="Enter password"
            value={state.password}
            onChange={e => setState({ password: e.target.value })}
          />
          <br />
          <button type="submit" disabled={!state.login || !state.password}>
            Submit
          </button>
        </form>
        <br />
        <Link to="/signup">Don't have an account?</Link>
      </Fragment>
    )}
  </Component>
);
const TweetForm = ({ onSubmit, didMount }) => (
  <Component initialState={{ text: '' }} didMount={didMount}>
    {({ state, setState }) => (
      <Fragment>
        <h1>New Tweet</h1>
        <form
          onSubmit={e => {
            e.preventDefault();
            onSubmit(state.text);
          }}
        >
          <textarea
            placeholder="Say something"
            value={state.text}
            onChange={e => setState({ text: e.target.value })}
          />
          <br />
          <button type="submit" disabled={!state.text}>
            Send
          </button>
        </form>
      </Fragment>
    )}
  </Component>
);
const Tweet = ({ text, author, id }) => (
  <Fragment key={id}>
    <dt>
      <strong>@{author.username}</strong>
    </dt>
    <dd>{text}</dd>
    <hr />
  </Fragment>
);
const Tweets = ({ tweets, didMount }) => (
  <Component didMount={didMount}>
    <Link to="/new-tweet">New Tweet</Link>
    <dl>
      {tweets.map(tweet => (
        <Tweet {...tweet} />
      ))}
    </dl>
  </Component>
);
const App = () => (
  <Component
    initialState={{
      users: [],
      tweets: [],
      token: localStorage.getItem('token'),
      user: {},
      api: localStorage.getItem('baseURL')
        ? axios.create({
            baseURL: localStorage.getItem('baseURL'),
            headers: localStorage.getItem('token')
              ? { Authorization: `Bearer ${localStorage.getItem('token')}` }
              : {},
          })
        : null,
    }}
    didMount={async ({ state }) => {
      if (state.api && state.token) {
        const api = state.api;
        const {
          data: { token },
        } = await api.get('/sessions');
        localStorage.setItem('token', token);
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    }}
  >
    {({ state, setState }) => (
      <Router>
        <UrlForm
          default
          path="/set-api"
          onSubmit={async baseURL => {
            localStorage.removeItem('token');
            localStorage.setItem('baseURL', baseURL);
            setState({ api: axios.create({ baseURL }) }, () => navigate('/tweets'));
          }}
        />
        <Tweets
          path="/tweets"
          tweets={state.tweets}
          didMount={async () => {
            if (state.api) {
              const { data } = await state.api.get('/tweets');
              setState({ tweets: data });
            }
          }}
        />
        <TweetForm
          path="/new-tweet"
          didMount={() => !state.token && navigate('/login')}
          onSubmit={async text => {
            await state.api.post('/tweets', { text });
            navigate('/tweets');
          }}
        />
        <LoginForm
          path="/login"
          onSubmit={async ({ login, password }) => {
            const api = state.api;
            const {
              data: { token, user },
            } = await api.post('/sessions', {
              email: login,
              username: login,
              password,
            });
            localStorage.setItem('token', token);
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setState({ token, user, api }, () => navigate('/new-tweet'));
          }}
        />
        <SignupForm
          path="/signup"
          onSubmit={async payload => {
            const api = state.api;
            const {
              data: { token, user },
            } = await api.post('/users', payload);
            localStorage.setItem('token', token);
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setState({ token, user, api }, () => navigate('/new-tweet'));
          }}
        />
      </Router>
    )}
  </Component>
);
export default App;
